<template>
  <div class="grid formgrid mt-2 mb-2 p-fluid">
    <transition name="fade" mode="out-in">
      <div v-if="!ml.data.trainingFinished" class="col-12">
        <Message severity="info" :closable="false">{{ t('autocbm.projects.management.training.action.training.progress') }}</Message>
        <ProgressBar :value="ml.data.trainingProgress" style="height: 1em;" />
      </div>
      <div v-else class="col-12">
        <Message severity="success" :closable="false">{{ t('autocbm.projects.management.training.action.training.success') }}</Message>
      </div>
    </transition>
  </div>
</template>

<script>
import { reactive, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useMessage } from '@/dockone/app/message'

export default {
  name: 'TrainingStatus',
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const message = useMessage()

    const projectData = reactive({
      projectId: computed(() => store.getters['autocbm/project/projectId'])
    })
    const project = { data: projectData }

    const mlData = reactive({
      trainingProgress: computed(() => store.getters['autocbm/projectMl/trainingProgress']),
      trainingFinished: computed(() => mlData.trainingProgress === 100),
      progressUpdateInterval: undefined
    })
    const mlActions = {
      loadTrainingStatus (projectId) {
        return store.dispatch('autocbm/projectMl/loadTrainingStatus', projectId)
            .catch(() => {
              clearInterval(ml.data.progressUpdateInterval)
              ml.data.progressUpdateInterval = undefined
              message.error(t('autocbm.projects.management.training.action.training.status.error.summary'),
                  t('autocbm.projects.management.training.action.training.status.error.detail'))
            })
      }
    }
    const ml = { data: mlData, ...mlActions }

    watch(() => ml.data.trainingProgress, trainingProgress => {
      if (trainingProgress === 100 && ml.data.progressUpdateInterval) {
        clearInterval(ml.data.progressUpdateInterval)
        ml.data.progressUpdateInterval = undefined
      }
      if (trainingProgress < 100 && !ml.data.progressUpdateInterval) {
        ml.data.progressUpdateInterval = setInterval(() => ml.loadTrainingStatus(project.data.projectId), 500)
      }
    }, { immediate: true })

    return { t, ml }
  }
}
</script>

<template>
  <div class="training-settings">
    <Message severity="info" :closable="false" class="mb-6">{{ t('autocbm.projects.management.training.description') }}</Message>
    <div class="flex justify-content-end flex-wrap">
      <div class="flex align-items-center justify-content-center">
        <Button icon="pi pi-refresh" iconPos="right" :label="t('autocbm.projects.management.training.form.label.refreshRecommendation')" class="p-button-text"
                @click="$emit('refresh-meta-learning')" />
      </div>
    </div>
    <div class="grid formgrid mb-2 p-fluid">
      <div class="col-3 md:col-2">
        <p class="font-bold">
          <span>{{ t('autocbm.projects.management.training.form.label.partitioning') }}</span>
          <Badge value="i" class="ml-2" v-tooltip.right="t('autocbm.projects.management.training.help.partitioning')" />
        </p>
        <template v-if="loading">
          <h6 class="font-normal">{{ t('autocbm.projects.management.training.form.label.training') }}</h6>
          <Skeleton width="100%" height="1rem" />
          <h6 class="font-normal">{{ t('autocbm.projects.management.training.form.label.validation') }}</h6>
          <Skeleton width="100%" height="1rem" />
          <h6 class="font-normal">{{ t('autocbm.projects.management.training.form.label.test') }}</h6>
          <Skeleton width="100%" height="1rem" />
        </template>
        <template v-else>
          <h6 class="font-normal">{{ t('autocbm.projects.management.training.form.label.training') }}: {{ ml.data.partitionTraining }}</h6>
          <Slider v-model="ml.data.partitionTraining" :disabled="training" @change="ml.updatePartitionTraining" />
          <h6 class="font-normal">{{ t('autocbm.projects.management.training.form.label.validation') }}: {{ ml.data.partitionValidation }}</h6>
          <Slider v-model="ml.data.partitionValidation" :disabled="training" @change="ml.updatePartitionValidation" />
          <h6 class="font-normal">{{ t('autocbm.projects.management.training.form.label.test') }}: {{ ml.data.partitionTest }}</h6>
          <Slider v-model="ml.data.partitionTest" :disabled="training" @change="ml.updatePartitionTest" />
        </template>
      </div>
      <div class="col-1 md:col-1">
        <Divider layout="vertical" />
      </div>
      <div class="col-8 md:col-9">
        <p class="font-bold">
          <span>{{ t('autocbm.projects.management.training.form.label.algorithm') }}</span>
          <Badge value="i" class="ml-2" v-tooltip.right="t('autocbm.projects.management.training.help.algorithm')" />
        </p>
        <Skeleton v-if="loading" width="100%" height="3rem" />
        <SelectButton v-else v-model="ml.data.algorithmSelection" :options="ml.data.algorithmSelectionOptions"
                      dataKey="key" optionValue="key" optionLabel="name" @change="ml.selectAlgorithms($event.value)" :disabled="loading || training" />
        <div class="training-algorithms">
          <div class="grid formgrid p-fluid">
            <template v-if="loading">
              <div v-for="algorithm in 3" :key="`algorithm-${algorithm}`" class="training-algorithm col-12 md:col-4">
                <Divider align="left">
                  <Skeleton width="10rem" height="2rem" />
                </Divider>
                <div class="training-algorithm-param">
                  <Skeleton v-for="param in 2" :key="`algorithm-${algorithm}-param-${param}`" width="100%" height="3rem" class="m-3" />
                </div>
              </div>
            </template>
            <template v-if="!loading && ml.data.metaLearningResult && ml.data.algorithmSettings">
              <div v-for="(algorithm, index) in ml.data.metaLearningResult" :key="algorithm.id" class="training-algorithm col-12 md:col-6">
                <Divider align="left">
                  <Checkbox :binary="true" :modelValue="ml.data.algorithmSettings[algorithm.id].enabled"
                            @update:modelValue="ml.selectAlgorithmSetting(algorithm.id, $event)"
                            :class="{ 'p-disabled': training }" class="mr-3" />
                  <b>{{ index + 1 }}.</b>&nbsp;
                  <b>{{ algorithm.name }}</b>&nbsp;
                  <b v-if="index === 0">({{ t('autocbm.projects.management.training.form.label.recommend') }})</b>
                </Divider>
                <div class="training-algorithm-param">
                  <div class="training-algorithm-model-name m-4">
                    <span class="p-float-label">
                      <InputText :id="`modelName-${algorithm.id}`" type="text" v-model="ml.data.algorithmSettings[algorithm.id].name" />
                      <label :for="`modelName-${algorithm.id}`">{{ t('autocbm.projects.management.training.form.label.modelName') }}</label>
                    </span>
                  </div>
                  <TrainingAlgorithmParam v-for="param in algorithm.params" :key="param.id" :id="param.id" :name="param.name" :paramType="param.paramType"
                                          :defaultValue="param.defaultValue"
                                          :modelValue="ml.data.algorithmSettings[algorithm.id].params[param.id]"
                                          @update:modelValue="ml.updateAlgorithmSetting(algorithm.id, param.id, $event)"
                                          :options="param" :disabled="training" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="grid formgrid p-fluid mt-4">
      <div class="col-12 md:col-4 xl:col-2 col-offset-0 md:col-offset-8 xl:col-offset-10">
        <Button icon="pi pi-caret-right" iconPos="right" :label="t('autocbm.projects.management.training.form.label.startTraining')"
                :disabled="!ml.data.trainingPossible" :loading="training" class="p-button" @click="ml.startTraining" />
      </div>
    </div>
  </div>
</template>

<script>
import TrainingAlgorithmParam from './TrainingAlgorithmParam'

import { reactive, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash'

export default {
  name: 'TrainingSettings',
  components: { TrainingAlgorithmParam },
  props: {
    loading: { type: Boolean },
    training: { type: Boolean }
  },
  emits: ['refresh-meta-learning', 'training'],
  setup (props, { emit }) {
    const store = useStore()
    const { t } = useI18n()

    const mlData = reactive({
      metaLearningResult: computed(() => store.getters['autocbm/projectMl/metaLearningResult']),
      trainingSettings: computed(() => store.getters['autocbm/projectMl/trainingSettings']),
      partitionTraining: 80,
      partitionValidation: 10,
      partitionTest: 10,
      algorithmSettings: null,
      recommendAlgorithmId: computed(() => mlData.metaLearningResult && mlData.metaLearningResult.length > 0 ? mlData.metaLearningResult[0].id : null),
      recommendAlgorithm: computed(
          () => mlData.recommendAlgorithmId && mlData.algorithmSettings ? mlData.algorithmSettings[mlData.recommendAlgorithmId] : null),
      algorithmSelection: null,
      algorithmSelectionOptions: computed(() => ([
        { key: 'recommend', name: t('autocbm.projects.management.training.algorithm.form.label.selectRecommend') },
        { key: 'all', name: t('autocbm.projects.management.training.algorithm.form.label.selectAll') },
        { key: 'none', name: t('autocbm.projects.management.training.algorithm.form.label.selectNone') }
      ])),
      trainingPossible: computed(() => {
        return mlData.algorithmSettings && Object.values(mlData.algorithmSettings).map(algorithm => algorithm.enabled).includes(true)
      })
    })
    const actions = {
      updatePartitionTraining (value) {
        const remaining = 100 - value
        ml.data.partitionValidation = Math.ceil(remaining / 2)
        ml.data.partitionTest = Math.floor(remaining / 2)
      },
      updatePartitionValidation (value) {
        const remaining = 100 - ml.data.partitionTraining - value
        if (remaining >= 0) {
          ml.data.partitionTest = remaining
        } else {
          ml.data.partitionValidation = value + remaining
        }
      },
      updatePartitionTest (value) {
        const remaining = 100 - ml.data.partitionTraining - value
        if (remaining >= 0) {
          ml.data.partitionValidation = remaining
        } else {
          ml.data.partitionTest = value + remaining
        }
      },
      selectAlgorithms (value) {
        if (mlData.algorithmSettings) {
          const settings = { ...mlData.algorithmSettings }
          if (value === 'recommend') {
            for (const [key, value] of Object.entries(settings)) {
              if (key === mlData.recommendAlgorithmId) {
                value.enabled = true
              } else {
                value.enabled = false
              }
            }
          }
          if (value === 'all') {
            for (const value of Object.values(settings)) {
              value.enabled = true
            }
          }
          if (value === 'none') {
            for (const value of Object.values(settings)) {
              value.enabled = false
            }
          }
          mlData.algorithmSettings = settings
        }
      },
      selectAlgorithmSetting (algorithmId, value) {
        const settings = { ...mlData.algorithmSettings }
        settings[algorithmId].enabled = value
        mlData.algorithmSettings = settings
      },
      updateAlgorithmSetting (algorithmId, paramId, value) {
        const settings = { ...mlData.algorithmSettings }
        settings[algorithmId].params[paramId] = value
        mlData.algorithmSettings = settings
      },
      startTraining () {
        const partition = { training: mlData.partitionTraining, validation: mlData.partitionValidation, test: mlData.partitionTest }
        const algorithm = mlData.algorithmSettings
        emit('training', { partition, algorithm })
      }
    }
    const ml = { data: mlData, ...actions }

    watch(() => ml.data.metaLearningResult, metaLearningResult => {
      if (metaLearningResult && !ml.data.algorithmSettings) {
        const algorithmSettings = {}
        metaLearningResult.forEach(algorithm => {
          const paramSettings = {}
          algorithm.params.forEach(param => {
            paramSettings[param.name] = param.defaultValue
          })
          algorithmSettings[algorithm.id] = { name: algorithm.name, params: paramSettings, enabled: algorithm.id === ml.data.recommendAlgorithmId }
        })
        ml.data.algorithmSettings = algorithmSettings
      }
    }, { immediate: true })
    watch(() => ml.data.trainingSettings, trainingSettings => {
      if (trainingSettings && trainingSettings.partition) {
        const partitionSettings = trainingSettings.partition
        if (partitionSettings.training && partitionSettings.validation && partitionSettings.test) {
          ml.data.partitionTraining = partitionSettings.training
          ml.data.partitionValidation = partitionSettings.validation
          ml.data.partitionTest = partitionSettings.test
        }
      }
      if (trainingSettings && trainingSettings.algorithm && !isEmpty(trainingSettings.algorithm)) {
        ml.data.algorithmSettings = trainingSettings.algorithm
      }
    }, { immediate: true })
    watch(() => ml.data.algorithmSettings, algorithmSettings => {
      if (algorithmSettings && ml.data.recommendAlgorithm) {
        const allSelected = Object.values(algorithmSettings).map(algorithm => algorithm.enabled).reduce((prev, curr) => prev && curr)
        const noneSelected = !Object.values(algorithmSettings).map(algorithm => algorithm.enabled).reduce((prev, curr) => prev || curr)
        const recommendSelected = ml.data.recommendAlgorithm.enabled
        if (allSelected) {
          ml.data.algorithmSelection = 'all'
        } else if (noneSelected) {
          ml.data.algorithmSelection = 'none'
        } else if (recommendSelected) {
          ml.data.algorithmSelection = 'recommend'
        }
      }
    }, { immediate: true })

    return { t, ml }
  }
}
</script>

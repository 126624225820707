<template>
  <div class="grid formgrid mt-2 mb-2 p-fluid">
    <div class="col-12">
      <Message severity="info" :closable="false">{{ t('autocbm.projects.management.training.action.metaLearning.progress') }}</Message>
      <ProgressBar :value="ml.data.metaLearningProgress" style="height: 1em;" />
    </div>
  </div>
</template>

<script>
import { reactive, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useMessage } from '@/dockone/app/message'

export default {
  name: 'MetaLearning',
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const message = useMessage()

    const projectData = reactive({
      projectId: computed(() => store.getters['autocbm/project/projectId'])
    })
    const project = { data: projectData }

    const mlData = reactive({
      metaLearningProgress: computed(() => store.getters['autocbm/projectMl/metaLearningProgress']),
      progressUpdateInterval: undefined
    })
    const mlActions = {
      loadMetaLearningStatus (projectId) {
        return store.dispatch('autocbm/projectMl/loadMetaLearningStatus', projectId)
            .catch(() => {
              clearInterval(ml.data.progressUpdateInterval)
              ml.data.progressUpdateInterval = undefined
              message.error(t('autocbm.projects.management.training.action.metaLearning.status.error.summary'),
                  t('autocbm.projects.management.training.action.metaLearning.status.error.detail'))
            })
      }
    }
    const ml = { data: mlData, ...mlActions }

    watch(() => ml.data.metaLearningProgress, metaLearningProgress => {
      if (metaLearningProgress === 100 && ml.data.progressUpdateInterval) {
        clearInterval(ml.data.progressUpdateInterval)
        ml.data.progressUpdateInterval = undefined
      }
    }, { immediate: true })

    onMounted(() => {
      if (ml.data.metaLearningProgress < 100) {
        ml.data.progressUpdateInterval = setInterval(() => ml.loadMetaLearningStatus(project.data.projectId), 500)
      }
    })

    return { t, ml }
  }
}
</script>

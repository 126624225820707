<template>
  <div class="training-algorithm-param m-4">
    <div v-if="data.booleanParam" class="field-checkbox">
      <Checkbox :id="id" :binary="true" :modelValue="data.value" @update:modelValue="updateValue" :class="{ 'p-disabled': disabled }" />
      <label :for="id">{{ name || id }}</label>
    </div>
    <span v-if="data.numericParam" class="p-float-label">
      <InputNumber :id="id" :min="data.numericMin" :max="data.numericMax" :step="data.numericStep"
                   :modelValue="data.value" @update:modelValue="updateValue" showButtons :class="{ 'p-disabled': disabled }" />
      <label :for="id">{{ name || id }}</label>
    </span>
    <span v-if="data.stringParam" class="p-float-label">
      <Dropdown v-if="data.stringParam" :id="id" :options="data.stringValues" :modelValue="data.value" @update:modelValue="updateValue"
                optionLabel="name" optionValue="name" dataKey="name" :disabled="disabled" />
      <label :for="id">{{ name || id }}</label>
    </span>
  </div>
</template>

<script>
import { reactive, computed } from 'vue'

export default {
  name: 'TrainingAlgorithmParam',
  props: {
    modelValue: { required: true },
    defaultValue: { required: true },
    id: { type: String, required: true },
    name: { type: String },
    paramType: { type: String, required: true },
    options: { type: Object, required: true },
    disabled: { type: Boolean }
  },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const data = reactive({
      value: computed(() => props.modelValue || props.defaultValue),
      booleanParam: computed(() => props.paramType === 'boolean'),
      numericParam: computed(() => props.paramType === 'numeric'),
      numericMin: computed(() => props.options.min ? props.options.min : null),
      numericMax: computed(() => props.options.max ? props.options.max : null),
      numericStep: computed(() => props.options.step ? props.options.step : null),
      stringParam: computed(() => props.paramType === 'string'),
      stringValues: computed(() => props.options.values ? props.options.values.map(value => ({ name: value })) : [])
    })
    const actions = {
      updateValue (value) {
        emit('update:modelValue', value)
      }
    }

    return { data, ...actions }
  }
}
</script>

<template>
  <ProjectLayout @cancel="returnToProjects" @back="$emit('step', 'project_data_select')"
                 :forward="ml.data.forwardStep" @forward="$emit('step', 'project_evaluation')">
    <template #mainContent>
      <transition name="fade" mode="out-in">
        <MetaLearning v-if="ml.data.processMetaLearning" />
        <TrainingSettings v-else :loading="ml.data.metaLearningLoading" :training="ml.data.processTraining"
                          @refreshMetaLearning="ml.startMetaLearning(project.data.projectId)"
                          @training="ml.training" />
      </transition>
    </template>
    <template v-if="!ml.data.processMetaLearning && !ml.data.trainingAvailable" #detailsTitle>
      <transition name="fade" mode="out-in">
        <span class="text">{{ t('autocbm.projects.management.training.help.title') }}</span>
      </transition>
    </template>
    <template v-if="!ml.data.processMetaLearning" #detailsContent>
      <transition name="fade" mode="out-in">
        <div v-if="!ml.data.trainingAvailable" class="help-text">
          <h6>{{ t('autocbm.projects.management.training.form.label.partitioning') }}</h6>
          <p class="text">{{ t('autocbm.projects.management.training.help.text.partitioning') }}</p>
          <h6>{{ t('autocbm.projects.management.training.form.label.algorithm') }}</h6>
          <p class="text">{{ t('autocbm.projects.management.training.help.text.algorithm') }}</p>

          <h6>{{ t('autocbm.projects.management.training.help.isolationForest') }}</h6>
          <p class="text">{{ t('autocbm.projects.management.training.help.text.isolationForest') }}</p>
          <h6>{{ t('autocbm.projects.management.training.help.ellipticEnvelope') }}</h6>
          <p class="text">{{ t('autocbm.projects.management.training.help.text.ellipticEnvelope') }}</p>
          <h6>{{ t('autocbm.projects.management.training.help.one-ClassSVM') }}</h6>
          <p class="text">{{ t('autocbm.projects.management.training.help.text.one-ClassSVM') }}</p>
          <h6>{{ t('autocbm.projects.management.training.help.one-ClassSVM(SGD)') }}</h6>
          <p class="text">{{ t('autocbm.projects.management.training.help.text.one-ClassSVM(SGD)') }}</p>
          <h6>{{ t('autocbm.projects.management.training.help.localOutlierFactor') }}</h6>
          <p class="text">{{ t('autocbm.projects.management.training.help.text.localOutlierFactor') }}</p>
        </div>
        <TrainingStatus v-else />
      </transition>
    </template>
  </ProjectLayout>
</template>

<script>
import ProjectLayout from '../../ProjectLayout'
import MetaLearning from './MetaLearning'
import TrainingSettings from './TrainingSettings'
import TrainingStatus from './TrainingStatus'

import { watch, reactive, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useMessage } from '@/dockone/app/message'

export default {
  name: 'Training',
  components: { ProjectLayout, MetaLearning, TrainingSettings, TrainingStatus },
  setup () {
    const router = useRouter()
    const store = useStore()
    const { t } = useI18n()
    const message = useMessage()

    const projectData = reactive({
      projectId: computed(() => store.getters['autocbm/project/projectId'])
    })
    const actions = {
      setWriteProtection (readonly) {
        store.dispatch('autocbm/project/setWriteProtection', readonly)
      }
    }
    const project = { data: projectData, ...actions }

    const mlData = reactive({
      metaLearningLoading: true,
      metaLearningProgress: computed(() => store.getters['autocbm/projectMl/metaLearningProgress']),
      processMetaLearning: false,
      trainingProgress: computed(() => store.getters['autocbm/projectMl/trainingProgress']),
      trainingResult: computed(() => store.getters['autocbm/projectMl/trainingResult']),
      trainingAvailable: computed(() => mlData.trainingProgress !== null || mlData.trainingResult !== null),
      processTraining: false,
      trainingSuccessful: computed(() => mlData.trainingProgress === 100 && ml.data.trainingResult),
      forwardStep: computed(() => !mlData.trainingSuccessful ? 'disabled' : null)
    })
    const mlActions = {
      loadMetaLearningStatus (projectId) {
        return store.dispatch('autocbm/projectMl/loadMetaLearningStatus', projectId)
            .catch(() => message.error(t('autocbm.projects.management.training.action.metaLearning.status.error.summary'),
                t('autocbm.projects.management.training.action.metaLearning.status.error.detail')))
      },
      startMetaLearning (projectId) {
        mlData.processMetaLearning = true
        return store.dispatch('autocbm/projectMl/startMetaLearning', projectId)
            .catch(() => message.error(t('autocbm.projects.management.training.action.metaLearning.start.error.summary'),
                t('autocbm.projects.management.training.action.metaLearning.start.error.detail')))
      },
      loadTrainingSettings (projectId) {
        return store.dispatch('autocbm/projectMl/loadTrainingSettings', projectId)
            .catch(() => message.error(t('autocbm.projects.management.training.action.training.settings.error.summary'),
                t('autocbm.projects.management.training.action.training.settings.error.detail')))
      },
      loadTrainingStatus (projectId) {
        return store.dispatch('autocbm/projectMl/loadTrainingStatus', projectId)
            .catch(() => message.error(t('autocbm.projects.management.training.action.training.status.error.summary'),
                t('autocbm.projects.management.training.action.training.status.error.detail')))
      },
      training (trainingSettings) {
        mlData.processTraining = true
        project.setWriteProtection(true)
        const projectId = project.data.projectId
        return store.dispatch('autocbm/projectMl/saveTrainingSettings', { projectId, trainingSettings })
            .then(() => store.dispatch('autocbm/projectMl/startTraining', projectId))
            .catch(() => message.error(t('autocbm.projects.management.training.action.training.start.error.summary'),
                t('autocbm.projects.management.training.action.training.start.error.detail')))
      }
    }
    const ml = { data: mlData, ...mlActions }

    const returnToProjects = () => router.push({ name: 'projects' })

    watch(() => project.data.projectId, projectId => {
      if (projectId) {
        ml.data.metaLearningLoading = true
        ml.loadMetaLearningStatus(projectId)
            .then(metaLearning => {
              if (!metaLearning || !metaLearning.progress || metaLearning.progress < 100) {
                ml.startMetaLearning(projectId)
              } else {
                return ml.loadTrainingSettings(projectId)
              }
            })
            .then(() => ml.loadTrainingStatus(projectId))
            .finally(() => ml.data.metaLearningLoading = false)
      }
    }, { immediate: true })
    watch(() => ml.data.metaLearningProgress, progress => {
      if (progress === 100) {
        ml.data.processMetaLearning = false
      }
    })
    watch(() => ml.data.trainingProgress, progress => {
      if (progress && progress >= 0 && progress < 100) {
        ml.data.processTraining = true
        project.setWriteProtection(true)
      }
      if (progress === 100) {
        ml.data.processTraining = false
        project.setWriteProtection(false)
      }
    }, { immediate: true })

    return { t, project, ml, returnToProjects }
  }
}
</script>
